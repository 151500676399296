
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

::v-deep .esmp-datepicker-dropdown {
  z-index: 100;
}
.page__action-buttons {
  z-index: 10;
}

.business-trip {
  &__result {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: $color-white;
    padding: 20px 16px;
    border-radius: $base-border-radius;
    margin-top: 20px;
  }
  &__result-inner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
  }
  &__result-name {
    font-size: 16px;
    letter-spacing: 0;
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
  &__result-value {
    font-size: 24px;
    color: $color-black;
    letter-spacing: 0;
    margin-left: 16px;
  }
  &__result-actions {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: -20px;
    ::v-deep .esmp-button {
      margin-left: 20px;
    }
  }

  &__footnote ::v-deep .esmp-link {
    font-size: 16px;
  }
  &__error,
  ::v-deep &__error {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    word-break: break-word;
    font-feature-settings: "tnum", "lnum";
    padding: 0 12px;
    color: $color-error-night;
   }
}
